<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ISFP</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Seseorang yang berkepribadian ISFP sering disebut The Artist atau seniman. Ada pribadi yang termasuk ISFP yakni 5-9% populasi di dunia. Dimana menurut dimensinya ISFP berarti Introversion atau seseorang yang tidak suka akan hubungan dan dunia luar. Sensing yaitu mereka yang mengenal hal-hal secara konkret atau nyata.  Feeling yaitu mereka memutuskan berbagai hal dengan perasaan dan menyangkut pribadi serta Perception yakni pemikiran yang terbuka dan cenderung menunda keputusan demi kesepakatan.
          </p>
          <p>
            Ciri kepribadian ISFP adalah:
            <ul>
              <li> Menyukai pergerakan lambat, suka dengan hal yang dilakukan saat ini dan memiliki kesadaran yang mendalam.</li>
              <li> Tidak suka berurusan dengan teori dan juga memiliki pemikiran yang konkret</li>
              <li> Setia dan loyal kepada banyak orang. </li>
              <li> Memiliki sifat individualistis dan tidak memiliki keinginan untuk mengikuti siapapun dan tidak suka berorganisasi.</li>
            </ul>
          </p>
          <p>Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Jangan takut akan penolakan dan juga konflik, karena hal tersebut wajar terjadi.
              </li>
              <li>
                Asah dan kembangkan sisi kreatifitas dan seni dalam diri anda sebagai modal bagus dalam diri anda.
              </li>
              <li>
                Pekerjaan yang cocok adalah seniman, guru, dokter hewan, pemerhati anak, desainer dan penyanyi.

              </li>
            </ul>
          </p>
          <p>
            Asmara untuk ISFP adalah mereka yang memiliki kepribadian ESFJ atau ENFJ yang mungkin lebih bisa mengimbangi pribadi ISFP.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ISFP.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Introversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="introversion">Introversion</h5>
              <p>cenderung pendiam dan suka menyendiri</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Sensing.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="sensing">Sensing</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Feeling.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="feeling">Feeling</h5>
              <p>membuat keputusan berdasarkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Perceiving.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="perceiving">Perceiving</h5>
              <p>cenderung membiarkan pilihan tetap terbuka</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ISFP",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ISFP',
        slug: 'isfp'
      }
    }
  }
};
</script>

<style scoped>

</style>
